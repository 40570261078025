<template>
  <div id="Prize" class="prize">
    <div>
      <h6 class="font-h6">{{ localisedPrize("partnership") }}</h6>
      <h5 class="font-h5">{{ localisedPrize("title") }}</h5>
    </div>
    <div class="slide-pr">
      <div
        id="carouselExampleIndicators1"
        class="carousel slide"
        data-ride="carousel"
      >
        <ol class="carousel-indicators container" id="minimap1">
          <li
            data-target="#carouselExampleIndicators1"
            data-slide-to="0"
            class="active"
          ></li>
          <li data-target="#carouselExampleIndicators1" data-slide-to="1"></li>
          <li data-target="#carouselExampleIndicators1" data-slide-to="2"></li>
        </ol>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div class="slide-prize container" id="show-slide">
              <div class="div-left">
                <div class="div-left-logo">
                  <img src="../../assets/home/Group 200.png" alt="" />
                </div>
                <div class="div-right">
                  <div class="div-title">
                    <img src="../../assets/home/logogst.png" alt="" />
                    <h5>{{localisedPrize("titleSecond")}}</h5>
                  </div>
                  <div class="div-decre">
                    {{localisedPrize("content")}}
                  </div>
                </div>
              </div>
              <div class="div-right-img">
                <img class="img-prize" src="../../assets/home/award-img.png" alt="" />
              </div>
            </div>
          </div>
          <div
            class="carousel-item"
            v-for="(item, index) in prizes"            
            :key="index"
          >
            <div class="slide-prize container" id="show-slide">
              <div class="div-left">
                <div class="div-left-logo">
                  <img src="../../assets/home/Group 200.png" alt="" />
                </div>
                <div class="div-right">
                  <div class="div-title">
                    <img 
                      :src="`${assetsPath}${item.PrizeLogo.url}`"
                      alt=""
                    />
                    <h5>{{ item.Title }}</h5>
                  </div>
                  <div class="div-decre">
                    {{ item.Description }}
                  </div>
                </div>
              </div>
              <div class="div-right-img">
                <img class="img-prize"
                  :src="`${assetsPath}${item.Image.url}`"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          id="btn-click-prev1"
          data-target="#carouselExampleIndicators1"
          data-slide="prev"
        >
          <span class="carousel-control-prev-icon2" aria-hidden="true">
            <img src="../../assets/home/Vector 11.svg" alt="" />
          </span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          id="btn-click-next1"
          data-target="#carouselExampleIndicators1"
          data-slide="next"
        >
          <span class="carousel-control-next-icon2" aria-hidden="true">
            <img src="../../assets/home/Vector 12.svg" alt="" />
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
import prize from "../../locales/lang"
import "../../assets/style.css";
export default {
data() {
    return {
      prizes: [],
      activeLocale: "vi",
    };
  },
  async mounted() {

    if (this.$route.params.locale == null) {
      this.activeLocale = "vi";
    } else {
      this.activeLocale = this.$route.params.locale;
    }
    if (this.$route.params.locale == null) {
      await request
        .get(`prizes`)
        .then((response) => {
          this.prizes = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    } else {
      await request
        .get(`prizes?_locale=${this.$route.params.locale}`)
        .then((response) => {
          this.prizes = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    }
  }, 
  methods:{
      localisedPrize(key) {
        var prizeLang = JSON.parse(JSON.stringify(prize.prize))
      return prizeLang[this.activeLocale][key];
    },

    // async getPrize() {
    //   await axios
    //   .get(`https://cms.gstech.space/prizes`)
    //   .then((reponse) => {
    //     this.prizes = reponse.data
    //   })
    //   .catch((e) => {
    //   })
    // }
  },
  computed:{
    assetsPath(){
      return process.env.VUE_APP_MY_ENV_ROOT_ASSETS 
      //${assetsPath}
    }
  }
};
</script>

<style>
.prize {
  overflow-x: hidden;
}
.img-prize{
  object-fit: cover
}
/* .carousel-indicators{
  margin-left: 0 !important;
  margin-right:0 !important
} */
</style>