<template>
  <section id="Product" class="product">
    <div class="container">
      <div class="productHot">
        <div>
          <h5>{{ localisedProduct("Title") }}</h5>
          <h2>{{ localisedProduct("productHot") }}</h2>
        </div>
        <div class="" v-for="(item, index) in producthots" :key="index">
          <div
            :id="item.ProductName"
            :class="
              index % 2 == 0 ? 'product-item row-reverse' : 'product-item'
            "
          >
            <div class="product-item-left">
              <img
                class="imgproduct"
                :src="`${assetsPath}${item.Image.url}`"
                alt=""
              />
            </div>
            <div class="product-item-right">
              <div class="intro-logo">
                <div>
                  <img
                    class="logoproduct"
                    :src="`${assetsPath}${item.logoProduct.url}`"
                    alt=""
                  />
                </div>
                <div class="div-click">
                  <a class="click" @click="modalVid(index)">{{
                    localisedProduct("clickVideo")
                  }}</a>
                  <img
                    @click="modalVid(index)"
                    class="btnclick"
                    src="../../assets/home/Group 133.svg"
                    alt=""
                  />
                </div>
                <div class="modal-video" ref="modal" v-if="showModal">
                  <div class="modal-video-body">
                    <div class="modal-video-inner">
                      <div class="modal-video-movie-wrap">
                        <div class="modal-video-close-btn">
                          <img
                            src="../../assets/home/close.svg"
                            id="btn-close-modal"
                            type="button"
                            v-if="showModal"
                            @click="showModal = false"
                          />
                        </div>
                        <iframe
                          width="560"
                          height="315"
                          :src="selectedVid.videoUrl"
                          title="YouTube video player"
                          frameborder="0"
                          allowfullscreen="true"
                          tabindex="-1"
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="content">
                <h6>{{ item.Description }}</h6>
              </div>
              <div class="kham-pha">
                <!-- <a :href="item.productLink"> -->
                <button
                  @click="GoProductPage(item.productLink)"
                  class="btn-discovery"
                >
                  {{ localisedProduct("Btn") }}
                  <img src="../../assets/home/muiten.svg" alt="" />
                </button>
                <!-- </a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import request from "@/utils/request";
import "../../assets/style.css";
import productHot from "../../locales/lang"
export default {
  data() {
    return {
      producthots: [],
      showModal: false,
      selectedVid: [],
      activeLocale: "vi",
      
    };
  },
  methods: {
    GoProductPage: function (link) {
      setTimeout(function () {
        window.open(link);
      }, 500);
    },
    modalVid(index) {
      this.showModal = true;
      this.selectedVid = this.producthots[index];
    },
    localisedProduct(key) {
      var productLang = JSON.parse(JSON.stringify(productHot.productHot))
      return productLang[this.activeLocale][key];
    },
  },

  async mounted() {
    if (this.$route.params.locale == null) {
      this.activeLocale = "vi";
    } else {
      this.activeLocale = this.$route.params.locale;
    }

    if (this.$route.params.locale == null) {
      await request
        .get(`product-hots`)
        .then((response) => {
          this.producthots = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    } else {
      await request
        .get(`product-hots?_locale=${this.locale}`)
        .then((response) => {
          this.producthots = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    }
  },
  computed: {
    locale() {
      return this.$route.params.locale;
    },
    assetsPath(){
      return process.env.VUE_APP_MY_ENV_ROOT_ASSETS 
      //${assetsPath}
    }
  },
 
};
</script>

<style>
@media only screen and (max-width: 780px) {
  .modal-video-close-btn {
    top: -10px;
  }
}
</style>