export default{ 
  menu: {
  en: {
    home: "Home",
    about: "About Us",
    newspaper: "Newspaper",
    recruit: "Recruit",
    contact: "Contact"
  },
  vi: {
    home: "Trang chủ",
    about: "Về chúng tôi",
    newspaper: "Tin tức",
    recruit: "Tuyển dụng",
    contact: "Liên hệ"
  }
},
investLang: {
  en: {
   investment: "gostream receives investment",
    title: "Invest",
    titleSecond:"Completed “SEED ROUND” with Zone Startup fund",
    content:"In October 2019, with an impressive presentation in the Startup accelerator program, GoStream excellently received an investment decision from Zone Startups Vietnam, part of the global Zone Startups network."
  },
  vi: {
    investment: "GoStream nhận được đầu tư",
    title: "Đầu tư",
    titleSecond: 'HOÀN TẤT ""SEED ROUND"" CÙNG QUỸ ZONE START',
    content:"Tháng 10/2019, với bài thuyết trình đầy ấn tượng trong chương trình tăng tốc Khởi nghiệp, GoStream đã xuất sắc nhận được quyết định đầu tư từ Zone Startups Việt Nam một phần của mạng lưới Zone Startups toàn cầu."
  },
},
applySuccess: {
  en: {
    notiSuccess: "Submission successful!",
    notiPosition:
      "Congratulations, you have successfully submitted your application for the position",
    btnBack: "Back to recruitment page",
  },
  vi: {
    notiSuccess: "Gửi thành công",
    notiPosition:
      "Xin chúc mừng, bạn đã gửi đơn ứng tuyển thành công cho vị trí",
    btnBack: "Quay lại trang tuyển dụng",
  },
},
jobDetail: {
  en: {
    btnApply: "Apply now",
    textRequirements:"Job requirements",
    textSkill:"Skills you need",
    textBenefits:"Benefits enjoyed",
    textOtherJobs:"Other recruitment news",
    seeAll:"View all job postings",
    Company:"GoStream Technology JSC"
  },
  vi: {
    btnApply: "Ứng tuyển ngay",
    textRequirements:"Yêu cầu công việc",
    textSkill:"Kỹ năng bạn cần",
    textBenefits:"Quyền lợi được hưởng",
    textOtherJobs:"Tin tuyển dụng khác",
    seeAll:"Xem tất cả tin tuyển dụng",
    Company:"Công ty cổ phần công nghệ GoStream"
  },
},
ceo: {
  en: {
    title: "GoStream Team",
  },
  vi: {
    title: "Đội ngũ GoStream",
  },
},
productHot: {
  en: {
    Btn: "Discover now",
    Title: "GoStream products",
    productHot: "Featured products",
    clickVideo: "Watch video",
  },
  vi: {
    Btn: "Khám phá ngay",
    Title: "Sản phẩm của GoStream",
    productHot: "Các sản phẩm nổi bật",
    clickVideo: "Click để xem video",
  },
},
prize: {
  en: {
   partnership: "GOSTREAM ACHIEVEMENTS",
    title: "Awards",
    titleSecond:'Second Prize in "Nhân tài Đất Việt 2019"',
    content:'Gostudio, an intellectual product created from the young Vietnamese people, was honored to be awarded the Second Prize of the 15th year of the "Nhân tài Đất Việt 2019"'
  },
  vi: {
    partnership: "THÀNH TÍCH GOSTREAM",
    title: "Các giải thưởng",
    titleSecond:"Giải nhì Nhân tài đất Việt năm 2019",
    content:"Gostudio, một sản phẩm trí tuệ được sáng tạo từ trí óc của những người Việt trẻ, đã vinh dự được trao giải Nhì của Giải thưởng Nhân tài Đất Việt năm thứ 15."
  },
},
news: {
  en: {
    title: "GoStream Reputation",
    titleSecond: "Press write about us",
    btnBack:"Back",
    btnNext:"Next"
  },
  vi: {
    title: "Danh tiếng GoStream",
    titleSecond: "Báo chí viết về chúng tôi",
     btnBack:"Quay lại",
     btnNext:"Tiếp theo"
  },
},
job: {
  en: {
    countRecruit: "job postings",
    btnDetail: "See Detail",
    textOtherJobs: "Other recruitment news",
    btnDetail:"See Detail"
  },
  vi: {
    countRecruit: "tin tuyển dụng",
    btnDetail: "Xem chi tiết",
    textOtherJobs: "Tin tuyển dụng khác",
    btnDetail:"Xem chi tiết"
  },
},
contact: {
  en: {
    connectGS: "Connect to GoStream",
    contactName: "Contact",
    headquartersTitle: "headquarters",
    headquarters:
      "Tầng 5 - 75A Nguyễn Thị Minh Khai, TP.Vinh, Nghệ An, Việt Nam",
    representativeTitle: "Office HCM",
    representativeHNTitle: "Office Ha Noi",
    // representativeOffice:
    // "Tầng 7, Tòa nhà Minh Long, số 17 Bà Huyện Thanh Quan, Quận 3, Tp. Hồ Chí Minh, Việt Nam",
    phoneNumberTitle: "Phone Number",
    socialNetworkTitle: "Social Network",
    contentTitle: "Content",
    btnSubmit: "Submit",
    nullEmail: "Your email cannot be empty",
    nullPhone: "Your phone number cannot be empty",
    nullContent: "Your content cannot be empty",
    capchaText: "Please verify you are not a robot",
  },
  vi: {
    connectGS: "Kết nối với GoStream",
    contactName: "Liên hệ",
    headquartersTitle: "Trụ sở",
    headquarters:
      "Tầng 5 - 75A Nguyễn Thị Minh Khai, TP.Vinh, Nghệ An, Việt Nam",
    representativeTitle: "Văn phòng HCM",
    representativeHNTitle: "Văn phòng Hà Nội",
    // representativeOffice:
    //   "Tầng 7, Tòa nhà Minh Long, số 17 Bà Huyện Thanh Quan, Quận 3, Tp. Hồ Chí Minh, Việt Nam",
    phoneNumberTitle: "Số điện thoại",
    socialNetworkTitle: "Mạng xã hội",
    contentTitle: "Nội dung",
    btnSubmit: "Gửi ngay",
    nullEmail: "Email của bạn không được để trống",
    nullPhone: "Số điện thoại không được để trống",
    nullContent: "Nội dung không được để trống",
    capchaText: "Vui lòng xác minh bạn không phải là ro-bot",
  },
},
slider: {
  en: {
    Btn: "Discover now",
    titleGoStream: "GoStream products",
    nameProduct: "Pre-recorded video streaming software",
    descriptionProduct:
      "Go live immediately or schedule automatic broadcastings using pre-recorded video to 20+ platforms at the same time in order to save time and optimize costs.",
  },
  vi: {
    Btn: "Khám phá ngay",
    titleGoStream: "Sản phẩm của GoStream",
    nameProduct: "Phần mềm live stream từ video có sẵn",
    descriptionProduct:
      "Live stream ngay hoặc lên lịch phát livestream tự động từ video quay sẵn lên 20+ nền tảng cùng một lúc giúp tiết kiệm thời gian và tối ưu chi phí.",
  },
},
apply: {
  en: {
    textName: "Full Name:",
    textEmail: "Email:",
    textPhone: "Phone Number:",
    textIntroduce: "Introduce:",
    textUpload: "Upload CV:",
    texUpCv: "Up CV",
    vldCV: "You need to upload your CV file!",
    textSelectFile: "You have not selected a file",
    btnSubmit: "Submit",
    nullFile: "Your File CV cannot be empty",
  },
  vi: {
    textName: "Họ tên:",
    textEmail: "Email:",
    textPhone: "Số điện thoại:",
    textIntroduce: "Giới thiệu:",
    textUpload: "Tải lên CV:",
    texUpCv: "Tải CV",
    vldCV:"Bạn cần tải tệp CV của mình lên!",
    textSelectFile: "Bạn chưa chọn file",
    btnSubmit: "Gửi hồ sơ",
    nullFile: "CV của bạn không được để trống",
  },
},
applyFail:{
  en: {
    content: "Application submission failed!",
    notiFail: "Your profile has not been sent successfully, please try again",
    btnBack: "Back to recruitment page",
  },
  vi: {
    content: "Gửi hồ sơ thất bại!",
    notiFail: "Hồ sơ của bạn chưa được gửi đi thành công vui lòng thử lại",
    btnBack: "Quay lại trang tuyển dụng",
  },

}
}
