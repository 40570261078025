<template>
  <section class="ceo" id="ceo">
    <div ref="modal" class="ceo-popup" v-if="showModal">
      <div class="modal-content-ceo">
        <div class="btn-close-popup">
          <img
            src="../../assets/home/close.svg"
            id="btn-close-modal"
            type="button"
            v-if="showModal"
           @click="showModal = false" 
          />
        </div>
        <div class="content-main">
          <div style="display: none"></div>
          <h3 ref="name" id="name">{{ selectedCeo.nameLeader }}</h3>
          <h5 ref="position" id="position">{{ selectedCeo.position }}</h5>
          <h6 ref="intro-ceo" id="intro-ceo">{{ selectedCeo.description }}</h6>
        </div>
        <img
          class="buiding-popup"
          src="../../assets/home/Group 226.png"
          alt=""
        />
      </div>
    </div>
    <div class="title">
      <h5>{{setLang("title")}}</h5>
      <h2>Co-Founders</h2>
    </div>
    <div class="avatar-buiding">
      <img class="buiding" src="../../assets/home/building-0122.png" alt="" />
      <div class="avatar">
        <img
          v-for="(item, index) in leaders"
          :key="index"
          :src="`${assetsPath}${item.imageURL.url}`"
           @click="modalCeo(index)"
        />
      </div>
    </div>
  </section>
</template>
<script>
import "../../assets/style.css";
import request from "@/utils/request";
import ceo from "../../locales/lang"
export default {
  components: {},
  data() {
    return {
      showModal: false,
      leaders: [],
      activeLocale: "vi",
      selectedCeo: [],

      //
    };
  },
  methods: {
    modalCeo(index) {
      // this.showModal = true;
      // this.selectedCeo = this.leaders[index];
      if(index ==0){
        setTimeout(function () {
        window.open("https://www.facebook.com/boygiandi");
      }, 500); 
      }
      if(index == 2){
        setTimeout(function () {
        window.open("https://www.linkedin.com/in/pham-liem-eng-mba-bbba87a0/");
      }, 500); 
      }
        if(index == 1){
        setTimeout(function () {
        window.open("https://www.facebook.com/hoannt.it");
      }, 500); 
      }
    },
    setLang(key){
      var ceoLang = JSON.parse(JSON.stringify(ceo.ceo))
      return  ceoLang[this.activeLocale][key]
    }
  },
  async mounted() {
     if(this.$route.params.locale == null){
      this.activeLocale = 'vi'
    }
    else{
      this.activeLocale = this.$route.params.locale
    }
    if (this.$route.params.locale == null) {
      await request
        .get(`leaders`)
        .then((response) => {
          this.leaders = response.data;
        })
        .catch((e) => {});
    } else {
      await request
        .get(`leaders?_locale=${this.locale}`)
        .then((response) => {
          this.leaders = response.data;
        })
        .catch((e) => {
          
        });
    }
  },
  created() {},
  computed: {
    locale() {
      return this.$route.params.locale;
    },
    assetsPath(){
      return process.env.VUE_APP_MY_ENV_ROOT_ASSETS 
      //${assetsPath}
    }
  },


};
</script>

<style>
</style>