<template>
  <div>
<Header />
<Carousel />
<Feature />
<Product />
<Ceo />
<Prize />
<Invest />
<Partner />
<NewsPaper />
<Footer />

  </div>
</template>

<script>
import Header from '../../components/Header.vue'
import Product from './Product.vue'
import Ceo from './Team-ceo.vue'
import Prize from './Prize.vue'
import Invest from './Invest.vue'
import NewsPaper from './NewsPaper.vue'
import Partner from '../../components/partner.vue'
import Carousel from '../../components/Carousel.vue';
import Feature from '../../components/Feature.vue';
import Footer from '../../components/Footer.vue'  
 
export default {
    name: "Home",
    data(){
      return{
      }
    },
    components:{
        Header, Product, Ceo, Prize, Invest, Partner, NewsPaper, Carousel, Feature,Footer
    },
}
</script>
<style>
html{
   scroll-behavior: smooth;
}
</style>