<template style=" overflow-x: hidden;">
  <div
    id="carouselExampleIndicators"
    class="carousel slide"
    data-ride="carousel"
  >
    <ol class="carousel-indicators" id="minimap">
      <li
        data-target="#carouselExampleIndicators"
        data-slide-to="0"
        class="active"
      ></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
    </ol>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <div class="slide1">
          <div class="container div-parent">
            <div class="slide-header-left">
              <div class="left-top">
                <img src="../assets/home/Rectangle 133.svg" alt="" />
                <h5>{{ localisedCarousel("titleGoStream") }}</h5>
              </div>
              <div class="slide-logo">
                <img style="width:218px; height:65px" class="" src="../assets/home/logogsprd.svg" alt="" />
              </div>
              <h2>{{ localisedCarousel("nameProduct") }}</h2>
              <h3>
                {{ localisedCarousel("descriptionProduct") }}
              </h3>
              <div class="slide-logo">
                <button class="btn-discovery" @click="goToGoStream">
                  {{ localisedCarousel("Btn") }}
                  <img
                    style="position: relative; left: 8px"
                    src="../assets/home/muiten.svg"
                    alt=""
                  />
                </button>
              </div>
            </div>
            <div class="slide-header-right">
              <img src="../assets/home/Cover_GoStream.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div
        class="carousel-item"
        v-for="(item, index) in sliderhomes"
        :key="index"
      >
        <div class="slide2">
          <div class="container div-parent">
            <div class="slide-header-left">
              <div class="left-top">
                <img src="../assets/home/Rectangle 133.svg" alt="" />
                <h5>{{ item.titleGoStream }}</h5>
              </div>
              <div class="slide-logo">
                <img
                style="width:218px; height:75px"
                  :src="`${assetsPath}${item.sliderLogo.url}`"
                  alt=""
                />
              </div>
              <h2  class="fix-width"
             >{{ item.nameProduct }}</h2>
              <h3>{{ item.descriptionProduct }}</h3>
              <div class="slide-logo">
                <button
                  @click="goProductPage(item.productLink)"
                  class="btn-discovery"
                >
                  {{ localisedCarousel("Btn")
                  }}<img
                    style="position: relative; left: 12px"
                    src="../assets/home/muiten.svg"
                    alt=""
                  />
                </button>
              </div>
            </div>
            <div class="slide-header-right">
              <img
                class="imgProduct"
                 :class="
              item.classprd  == 'gostudio' ? 'gostudio' : 'imgproduct'
            "
                style="margin-left: 90px"
                :src="`${assetsPath}${item.imageProduct.url}`"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <button
      class="carousel-control-prev"
      type="button"
      id="btn-click-prev"
      data-target="#carouselExampleIndicators"
      data-slide="prev"
    >
      <span class="carousel-control-prev-icon1" aria-hidden="true">
        <img src="../assets/home/Vector 11.svg" alt="" />
      </span>
    </button>
    <button
      class="carousel-control-next"
      type="button"
      id="btn-click-next"
      data-target="#carouselExampleIndicators"
      data-slide="next"
    >
      <span class="carousel-control-next-icon1" aria-hidden="true">
        <img src="../assets/home/Vector 12.svg" alt="" />
      </span>
    </button>
  </div>
</template>

<script>
import "../assets/style.css";
import "../assets/boostrap.css";
import slider from "../locales/lang"
import request from "@/utils/request";
export default {
  data() {
    return {
      sliderhomes: [],
      activeLocale: "vi",
      
    };
  },
  async mounted() {

    if (this.$route.params.locale == null) {
      this.activeLocale = "vi";
    } else {
      this.activeLocale = this.$route.params.locale;
    }
    if (this.$route.params.locale == null) {
      await request
        .get(`slider-homes`)
        .then((response) => {
          this.sliderhomes = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    } else {
      await request
        .get(`slider-homes?_locale=${this.locale}`)
        .then((response) => {
          this.sliderhomes = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    }
  },
  methods: {
    localisedCarousel(key) {
      var sliderLang = JSON.parse(JSON.stringify(slider.slider))
      return sliderLang[this.activeLocale][key];
    },
    goToGoStream: function () {
     
      setTimeout(function () {
       
        window.open("https://gostream.co");
      }, 500);
    },
    goProductPage: function (link) {
      setTimeout(function () {
        window.open(link);
      }, 500);
    },
  },
  computed: {
    locale() {
      return this.$route.params.locale;
    },
    assetsPath(){
      return process.env.VUE_APP_MY_ENV_ROOT_ASSETS 
      //${assetsPath}
    }
  },
};
</script>

<style>
</style>