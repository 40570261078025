<template>
  <div class="partner container">
    <div class="partner-title">
      <h5>{{ setLang("title") }}</h5>
      <h2>{{ setLang("titleSecond") }}</h2>
    </div>
    <div
      id="carouselExampleIndicators2"
      class="carousel slide"
      data-ride="carousel"
      data-pause=false
    >
      <div class="carousel-inner">
        <div  class="carousel-item active">
          <div class="partner-logo" id="show-slide">
            <div
              class="logo-com"
              v-for="(item, index) in partners"
              :key="index"
                      >
              <img :src="`${assetsPath}${item.url}`" alt="" />
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div class="partner-logo" id="show-slide">
            <div
              class="logo-com"
              v-for="(partner, index) in partners"
              :key="index"
            >
              <img :src="`${assetsPath}${partner.url}`" alt="" />
            </div>
          </div>
        </div>
      </div>

      <button style="display:none"
        class="carousel-control-prev"
        type="button"
        id="btn-click-prev1"
        data-target="#carouselExampleIndicators2"
        data-slide="prev"
      >
        <span class="carousel-control-prev-icon2" aria-hidden="true">
          <img src="../assets/home/Vector 11.svg" alt="" />
        </span>
      </button>
      <button style="display:none"
        class="carousel-control-next"
        type="button"
        id="btn-click-next1"
        data-target="#carouselExampleIndicators2"
        data-slide="next"
      >
        <span class="carousel-control-next-icon2" aria-hidden="true">
          <img src="../assets/home/Vector 12.svg" alt="" />
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
import "../assets/style.css";
export default {
  data() {
    return {
      partners: [],
      activeLocale: "vi",
      partner: {
        en: {
          title: "GoStream partnership",
          titleSecond: "Partners - Customers",
        },
        vi: {
          title: "GoStream hợp tác",
          titleSecond: "Đối tác - Khách hàng",
        },
      },
    };
  },
  mounted() {
    if (this.$route.params.locale == null) {
      this.activeLocale = "vi";
    } else {
      this.activeLocale = this.$route.params.locale;
    }
  },
  methods: {
    setLang(key) {
      return this.partner[this.activeLocale][key];
    },
   
  },
  created() {
    request
      .get(`partners`)
      .then((response) => {
        for (let i = 0; i < response.data[0].PartnerLogo.length; i++) {
          this.partners = response.data[0].PartnerLogo;
        }
      })
      .catch((e) => {});
  },
  computed: {
    assetsPath() {
      return process.env.VUE_APP_MY_ENV_ROOT_ASSETS;
      //${assetsPath}
    },
  },
};
</script>

<style></style>
