<template>
  <div id="contact" class="footer-all">
    <div class="footer">
      <div class="footer-logo">
        <img src="../assets/home/GOSTREAM.png" alt="" />
      </div>
      <div class="footer-contact" v-for="(item, index) in contacts" :key="index">
        <div class="contact-title">
          <b>{{ setLang("connectGS") }}</b>
          <h4>{{ setLang("contactName") }}</h4>
        </div>
        <div class="headquater">
          <div class="headquater-left">
            <img src="../assets/home/Line 11.svg" alt="" />
          </div>
          <div class="headquater-right">
            <h5>{{ setLang("headquartersTitle") }}</h5>
            <h4>
              {{ item.Headquarters }}
            </h4>
          </div>
        </div>
        <div class="headquater">
          <div class="headquater-left">
            <img src="../assets/home/Line 11.svg" alt="" />
          </div>
          <div class="headquater-right">
            <h5>{{ setLang("representativeTitle") }}</h5>
            <h4>
              {{ item.representativeOffice }}
            </h4>
          </div>
        </div>
        <div class="headquater">
          <div class="headquater-left">
            <img src="../assets/home/Line 11.svg" alt="" />
          </div>
          <div class="headquater-right">
            <h5>{{ setLang("representativeHNTitle") }}</h5>
            <h4>
              {{ item.representativeOfficeHN }}
            </h4>
          </div>
        </div>
        <div class="headquater">
          <div class="headquater-left">
            <img src="../assets/home/Line 8.svg" alt="" />
          </div>
          <div class="headquater-right">
            <h5>Email</h5>
            <h4>{{item.Email}}</h4>
          </div>
        </div>
        <div class="headquater">
          <div class="headquater-left">
            <img src="../assets/home/Line 8.svg" alt="" />
          </div>
          <div class="headquater-right">
            <h5>{{ setLang("phoneNumberTitle") }}</h5>
            <h4>{{item.PhoneNumber}}</h4>
          </div>
        </div>
        <div class="headquater">
          <div class="headquater-left">
            <img src="../assets/home/Line 8.svg" alt="" />
          </div>
          <div class="headquater-right">
            <h5>{{ setLang("socialNetworkTitle") }}</h5>
            <div>
              <a href=""> <img src="../assets/home/iconfb.svg" alt="" /></a>
              <a href=""> <img src="../assets/home/youtube.svg" alt="" /></a>
              <a href=""><img src="../assets/home/medium.svg" alt="" /></a>
             
             
              
            </div>
          </div>
        </div>
      </div>
      <div class="footer-form">
        <div class="form">
          <form action="" method="post" @submit.prevent="submitInfoCustomer">
            <div class="form-group">
              <label for="">Email <b style="color: red">*</b></label>
              <input
                type="email"
                class="form-control"
                name=""
                id=""
                placeholder="Enter Email"
                v-model="customers.Email"
              />
              <small v-show="showValidateEmail" class="form-text">{{
                setLang("nullEmail")
              }}</small>
            </div>
            <div class="form-group">
              <label for=""
                >{{ setLang("phoneNumberTitle")
                }}<b style="color: red">*</b></label
              >
              <input
                type="number"
                class="form-control"
                name=""
                id=""
                placeholder="Enter phone number"
                v-model="customers.PhoneNumber"
              />
              <small v-show="showValidatePhone" class="form-text">{{
                setLang("nullPhone")
              }}</small>
            </div>
            <div class="form-group">
              <label for=""
                >{{ setLang("contentTitle")
                }}<b style="color: red"> *</b></label
              >
              <textarea
                class="form-control"
                id="exampleFormControlTextarea1"
                rows="5"
                placeholder="Enter content..."
                v-model="customers.NoteContent"
              ></textarea>
              <small v-show="showValidateContent" class="form-text">{{
                setLang("nullContent")
              }}</small>
            </div>
            <div class="form-group">
              <div class="hcapcha_failed" ></div>
              <vue-hcaptcha style="width: 360px"
                @verify="onVerify"
                sitekey="6eefffba-2fda-4111-a251-879a324333a9"
              ></vue-hcaptcha>
              <small v-show="showValidateCapcha">
                {{ setLang("capchaText") }}
              </small>
            </div>
            <div class="btn-submit">
              <button class="btn" type="submit">
                {{ setLang("btnSubmit") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <img class="footer-buiding" src="../assets/home/building-01 3.png" alt="" />
    <h5 class="footer-end">©2019 Gostream. all rights absolutely reserved.</h5>
  </div>
</template>

<script>

import request from '../utils/request'
// import { VueRecaptcha } from 'vue-recaptcha';
import VueHcaptcha from "@hcaptcha/vue-hcaptcha";
import contact from "../locales/lang"

export default {
  components: { VueHcaptcha },
  data() {
    return {
      size: "compact",
      activeLocale: "vi",
      contacts:[],
      showValidateEmail: false,
      showValidatePhone: false,
      showValidateContent: false,
      showValidateCapcha: false,
      hCapcha: null,
      customers: {
        Email: "",
        PhoneNumber: "",
        NoteContent: "",
      },
    };
  },
  
  methods: {
   async getContact(){
    await request
     .get(`contact-fts`)
        .then((response) => {
          this.contacts = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        });

    },
    setLang(key) {
      var contactLang = JSON.parse(JSON.stringify(contact.contact))
      return contactLang[this.activeLocale][key];
    },

    //HCapcha
    onVerify(rps) {
      this.hCapcha = rps; 
    },
    
    submitInfoCustomer() {
      // bad code, fix later
      if (this.customers.Email == "") {
        this.showValidateEmail = true;
      }
      if(this.hCapcha == null){ 
        this.showValidateCapcha = true;
      }
      if (this.customers.PhoneNumber == "") {
        this.showValidatePhone = true;
      }
      if (this.customers.NoteContent == "") {
        this.showValidateContent = true;
      }
      if (
        this.customers.Email == "" &&
        this.customers.PhoneNumber == "" &&
        this.customers.NoteContent == "" &&
        this.hCapcha == null
      ) {
        this.showValidatePhone = true;
        this.showValidateEmail = true;
        this.showValidateContent = true;
        this.showValidateCapcha = true
      }
      if (this.customers.PhoneNumber != "") {
        this.showValidatePhone = false;
      }
      if (this.customers.Email != "") {
        this.showValidateEmail = false;
      }
      if (this.customers.NoteContent != "") {
        this.showValidateContent = false;
      }
        if (this.hCapcha != null) {
        this.showValidateCapcha = false;
      }
      if (
        this.customers.Email != "" &&
        this.customers.PhoneNumber != "" &&
        this.customers.NoteContent != ""
        && this.hCapcha != null
      ) {
        request
          .post("customers", this.customers)
          .then((res) => { 
            //Perform Success Action
            confirm(
              "Thông tin liên hệ đã được gửi. Chúng tôi sẽ sớm liên lạc lại với bạn!"
            );
            window.location.assign("/");
          })
          .catch((error) => {
          });
      }
    },
  },
  mounted() {
    if (this.$route.params.locale === 'en') {
      this.activeLocale = "en";
      
    } else { 
      this.activeLocale = this.$route.params.locale;
    }

    if(this.locale === 'vi'){
      this.getContact()
    }
    else{
       request
     .get(`contact-fts?_locale=${this.locale}`)
        .then((response) => {
          this.contacts = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    }
  },
  computed:{
      locale() {
      return this.$route.params.locale;
    },
  }
};
</script>

<style scoped>
.form-group .hcapcha_failed small {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #eb414b;
}
/* .form-group #hcap-script iframe{
  width: 380px;
}
#anchor{
widows: 400px;
} */
</style>