<template>
  <section
    style="overflow-x: hidden"
    id="Newspaper"
    ref="Newspaper"
    class="newspaper"
  >
    <div class="partner-title">
      <h5>{{ setLang("title") }}</h5>
      <h2>{{ setLang("titleSecond") }}</h2>
    </div>
    <VueSlickCarousel
      ref="slick"
      v-bind="settings"
      :options="slickOptions"
      class="container newspaper-detail"
      v-if="newspapers.length"
    >
      <div
        class="newspaper-detail-div"
        v-for="(item, index) in newspapers"
        :key="index"
      >
        <img class="news-img" :src="`${assetsPath}${item.image.url}`" alt="" />
        <div class="newspaper-content">
          <h5 @click="goToNews(item.linkNewspaper)">{{ item.magazineName }}</h5>
          <h4 @click="goToNews(item.linkNewspaper)">{{ item.Title }}</h4>
          <h6 @click="goToNews(item.linkNewspaper)">{{ item.Content }}</h6>
          <b>{{ moment(item.created_at).format("DD-MM-YYYY") }}</b>
        </div>
      </div>
    </VueSlickCarousel>
    <div class="paging">
      <b class="news-prev" @click="showPre">
        &lt;&lt; {{ setLang("btnBack") }}</b
      >
      <img src="../../assets/home/Vector 36.svg" alt="" />
      <b class="news-next" @click="showNext"
        >{{ setLang("btnNext") }} &gt;&gt;</b
      >
    </div>
  </section>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import Slick from "vue-slick";
import request from "@/utils/request";
import moment from "moment";
import news from "../../locales/lang";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import "../../assets/style.css";
export default {
  components: {
    Slick,
    VueSlickCarousel,
  },
  data() {
    return {
      activeLocale: "vi",

      settings: {
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        autoplay: false,
      },
      moment: moment,
      newspapers: [],
      slickOptions: {
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        autoplay: false,
      },
    };
  },
  async mounted() {
    if (this.$route.params.locale == null) {
      this.activeLocale = "vi";
    } else {
      this.activeLocale = this.$route.params.locale;
    }
    if (this.$route.params.locale == null) {
      await request
        .get(`news-papers`)
        .then((response) => {
          this.newspapers = response.data;
        })
        .catch((e) => {});
    } else {
      this.getNewspaper();
    }
  },
  methods: {
    setLang(key) {
      var newsLang = JSON.parse(JSON.stringify(news.news));
      return newsLang[this.activeLocale][key];
    },
    async getNewspaper() {
      await request
        .get(`news-papers?_locale=${this.locale}`)
        .then((response) => {
          this.newspapers = response.data;
        })
        .catch((e) => {});
    },
    goToNews: function (link) {
      setTimeout(function () {
        window.open(link);
      }, 500);
    },
    showNext() {
      this.$refs.slick.next();
    },

    showPre() {
      this.$refs.slick.prev();
    },

    reInit() {
      this.$nextTick(() => {
        this.$refs.slick.reSlick();
      });
    },
  },
  computed: {
    locale() {
      return this.$route.params.locale;
    },
    assetsPath() {
      return process.env.VUE_APP_MY_ENV_ROOT_ASSETS;
      //${assetsPath}
    },
  },
};
</script>

<style>
.news-img {
  width: 344px;
  border-radius: 10px;
  height: 205px;
  cursor: pointer;
  object-fit: cover
}
</style>
