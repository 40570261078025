var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"prize",attrs:{"id":"Prize"}},[_c('div',[_c('h6',{staticClass:"font-h6"},[_vm._v(_vm._s(_vm.localisedPrize("partnership")))]),_c('h5',{staticClass:"font-h5"},[_vm._v(_vm._s(_vm.localisedPrize("title")))])]),_c('div',{staticClass:"slide-pr"},[_c('div',{staticClass:"carousel slide",attrs:{"id":"carouselExampleIndicators1","data-ride":"carousel"}},[_vm._m(0),_c('div',{staticClass:"carousel-inner"},[_c('div',{staticClass:"carousel-item active"},[_c('div',{staticClass:"slide-prize container",attrs:{"id":"show-slide"}},[_c('div',{staticClass:"div-left"},[_vm._m(1),_c('div',{staticClass:"div-right"},[_c('div',{staticClass:"div-title"},[_c('img',{attrs:{"src":require("../../assets/home/logogst.png"),"alt":""}}),_c('h5',[_vm._v(_vm._s(_vm.localisedPrize("titleSecond")))])]),_c('div',{staticClass:"div-decre"},[_vm._v(" "+_vm._s(_vm.localisedPrize("content"))+" ")])])]),_vm._m(2)])]),_vm._l((_vm.prizes),function(item,index){return _c('div',{key:index,staticClass:"carousel-item"},[_c('div',{staticClass:"slide-prize container",attrs:{"id":"show-slide"}},[_c('div',{staticClass:"div-left"},[_vm._m(3,true),_c('div',{staticClass:"div-right"},[_c('div',{staticClass:"div-title"},[_c('img',{attrs:{"src":`${_vm.assetsPath}${item.PrizeLogo.url}`,"alt":""}}),_c('h5',[_vm._v(_vm._s(item.Title))])]),_c('div',{staticClass:"div-decre"},[_vm._v(" "+_vm._s(item.Description)+" ")])])]),_c('div',{staticClass:"div-right-img"},[_c('img',{staticClass:"img-prize",attrs:{"src":`${_vm.assetsPath}${item.Image.url}`,"alt":""}})])])])})],2),_vm._m(4),_vm._m(5)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ol',{staticClass:"carousel-indicators container",attrs:{"id":"minimap1"}},[_c('li',{staticClass:"active",attrs:{"data-target":"#carouselExampleIndicators1","data-slide-to":"0"}}),_c('li',{attrs:{"data-target":"#carouselExampleIndicators1","data-slide-to":"1"}}),_c('li',{attrs:{"data-target":"#carouselExampleIndicators1","data-slide-to":"2"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"div-left-logo"},[_c('img',{attrs:{"src":require("../../assets/home/Group 200.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"div-right-img"},[_c('img',{staticClass:"img-prize",attrs:{"src":require("../../assets/home/award-img.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"div-left-logo"},[_c('img',{attrs:{"src":require("../../assets/home/Group 200.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"carousel-control-prev",attrs:{"type":"button","id":"btn-click-prev1","data-target":"#carouselExampleIndicators1","data-slide":"prev"}},[_c('span',{staticClass:"carousel-control-prev-icon2",attrs:{"aria-hidden":"true"}},[_c('img',{attrs:{"src":require("../../assets/home/Vector 11.svg"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"carousel-control-next",attrs:{"type":"button","id":"btn-click-next1","data-target":"#carouselExampleIndicators1","data-slide":"next"}},[_c('span',{staticClass:"carousel-control-next-icon2",attrs:{"aria-hidden":"true"}},[_c('img',{attrs:{"src":require("../../assets/home/Vector 12.svg"),"alt":""}})])])
}]

export { render, staticRenderFns }