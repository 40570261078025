import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home";
Vue.use(VueRouter);
 function rePath1(){
  if(navigator.language =='vi-VN'){
    return  "/vi"   
   }
   else{
    return  "/en"   
   }
 }

const routes = [

  { path: '/', redirect: `${rePath1()}` },
  {
    path: "/:locale?",
    name: "Home",
    component: Home,
  },
  {
    path: "/about:locale",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/recruit/:locale",
    name: "Recruit",
    component: () =>
      import(/* webpackChunkName: "Recruit" */ "../views/Recruit/index.vue"),
  },
  {
    path: "/detail/:id/:locale",
    name: "Detail",
    // props: (route) => ({ id: route.params.id }),
    component: () =>
      import(/* webpackChunkName: "Detail" */ "../views/Recruit/Detail.vue"),
  },

  {
    path: "/apply/:id/:locale",
    name: "Apply",
    component: () =>
      import(/* webpackChunkName: "Apply" */ "../views/Recruit/Apply.vue"),
  },
  {
    path: "/applysuccess/:id/:locale",
    name: "ApplySuccess",
    component: () =>
      import(
        /* webpackChunkName: "Apply" */ "../views/Recruit/ApplySuccess.vue"
      ),
  },
  {
    path: "/applyfailed/:locale",
    name: "ApplyFailed",
    component: () =>
      import(
        /* webpackChunkName: "Apply" */ "../views/Recruit/ApplyFailed.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    window.scrollTo(0, 0);
  },
});
// router.beforeEach((to, from, next) => {
//   next({
//     name: to.name=='Recruit',
//     params: { locale: from.params.locale || 'en' }
//   })
// });

export default router;
