<template>
  <div id="app" class="custom-scrollbar">
    <router-view />
  </div>
</template>

<script>
import "./assets/style.css";
import "./assets/reset.css";
import "./assets/grid.css";
import "./assets/boostrap.css";

export default {
  name: "App",
  methods: {
    goto(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;
      window.scrollTo(0, top);
    },
  },
};
</script>

<style>
.custom-scrollbar{

}
/* custom scrollbar
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
} */


::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	background-color: #0ae;
	
	background-image: -webkit-gradient(linear, 0 0, 0 100%,
	                   color-stop(.5, rgba(255, 255, 255, .2)),
					   color-stop(.5, transparent), to(transparent));
}



#app {
  /* overflow-x: hidden; */
  height: calc(100% - 10px);
  background: #FAFAFA;
}


html,
body {
  height: 100vh;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  scroll-padding-top: 100px;
}
body {
  font-family: "Helvetica";
  font-style: normal;
  font-size: 15px;
  font-weight: 700;
  line-height: 26px;
}
</style>
