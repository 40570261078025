import Vue from 'vue'
import App from './App.vue'
import 'bootstrap'
import 'bootstrap-vue'
import VueRouter from 'vue-router';
import router from './router'
import { BootstrapVue, IconsPlugin  } from 'bootstrap-vue'
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { BPagination } from 'bootstrap-vue'
// import VueI18n from 'vue-i18n';
// import viLang from "./locales/vi.json"
// import enLang from "./locales/en.json"
Vue.component('b-pagination', BPagination)
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(VueLoading)
Vue.config.keyCodes.enter = 13
Vue.config.productionTip = false
Vue.use(VueRouter);
// Vue.use(VueI18n)
Window.Vue = require('vue')
// const messages = {
//   vn: viLang,
//   en: enLang,
// }
// const i18n = new VueI18n({
//   locale: 'vi', // set locale
//   messages,
//   fallbackLocale: 'vi',
// })
new Vue({
  router,
  // VueI18n,
  render: h => h(App),
}).$mount('#app')
// export default i18n