<template>
  <div id="header-top" class="header-top sticky-top">
    <nav class="navbar navbar-expand-lg navbar-light">
      <a class="navbar-brand" href="/">
        <img src="../assets/home/logo.svg" alt="" id="logo"
      /></a>
      <button
        class="navbar-toggler"
        id="btn-navbar"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class=""
          ><img
            src="../assets/home/align-right-svgrepo-com.svg"
            alt="GoStream Tech"
        /></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <ul class="navbar-nav" id="menu">
          <div class="list-nav-item">
            <li>
              <a
                @click="active = null"
                :class="{ active: active === null }"
                href="/"
                class="nav-link"
                >{{ localised("home") }}</a
              >
            </li>
            <li class="nav-item">
              <a
                @click="active = 'about'"
                :class="{ active: active === 'about' }"
                href="#about"
                class="nav-link"
                >{{ localised("about") }}</a
              >
            </li>
            <li class="nav-item">
              <a
                @click="active = 'newspaper'"
                :class="{ active: active === 'newspaper' }"
                href="#Newspaper"
                class="nav-link"
                >{{ localised("newspaper") }}</a
              >
            </li>
            <li class="nav-item">
              <!-- <a class="nav-link rttr"  @click="recruitClick">
                
              {{ localised("recruit") }}</a> -->
              <router-link class="nav-link" :to="{ name: 'Recruit' }">
                {{ localised("recruit") }}
              </router-link>
            </li>
            <li class="nav-item">
              <a
                @click="active = 'contact'"
                :class="{ active: active === 'contact' }"
                href="#contact"
                class="nav-link"
                >{{ localised("contact") }}</a
              >
            </li>
          </div>

          <li class="nav-item dropdown" id="language">
            <a
              class="nav-link"
              href="#"
              id="navbarDropdownMenuLink"
              role="button"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              <img :src="flag" alt="" />
              <b>{{ nation }}</b>
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <a class="language-Viet row" href="/vi">
                <div class="language-popup">
                  <img src="../assets/home/image 18.svg" alt="" />
                  <b value="vi">Tiếng Việt</b>
                </div>
              </a>
              <a class="language-English row" href="/en">
                <div class="language-popup">
                  <img src="../assets/home/image 19.svg" alt="" />
                  <b value="en">English</b>
                </div>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
import "../assets/style.css";
import "../assets/boostrap.css";
import "../views/Home/NewsPaper.vue";
import menu from "../locales/lang"
export default {
  data() {
    return {
      active: null,
      activeLocale: "vi"
    };
  },
  mounted() {
    if (this.locale == null) {
      this.activeLocale = "vi";
    } else {
      this.activeLocale = this.$route.params.locale;
    }
    //  if (localStorage.currentLanguage) {
    //             this.activeLocale = localStorage.currentLanguage;
    //         }
  },
  methods: {
    //  changeLanguage(language) {
    //     // set ngôn ngữ với key là currentLanguage
    //     localStorage.currentLanguage = language;
    //      this.activeLocale = language;
    //   },
    localised(key) {
      var menuLang = JSON.parse(JSON.stringify(menu.menu))
      return menuLang[this.activeLocale][key];
    },
  },
  computed: {
    locale() {
      return this.$route.params.locale;
    },

    nation() {
      if (this.$route.params.locale === "en") {
        return "EN";
      } else {
        return "VN";
      }
    },
    flag() {
      if (this.activeLocale === "en") {
        return require("../assets/home/image 19.svg");
      }
      return require("../assets/home/image 18.svg");
    },
  },
};
</script>

<style>
body,
html {
  scroll-behavior: smooth;
}
</style>