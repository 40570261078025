<template>
  <div  class="container feature">
    <div class="logo-feature">
      <div class="feature-detail">
        <img class="feature-avt" src="../assets/home/logo.svg" alt="" />
        <div class="btn-feature">
          <a href="#GoStream">
            <img src="../assets/home/circle.svg" alt="" />
            <img class="go-feature" src="../assets/home/go-r.svg" alt=""
          /></a>
        </div>
      </div>
      <div class="feature-detail">
        <img class="feature-avt" src="../assets/home/gostudio.png" alt="" />

        <div class="btn-feature">
          <a href="#GoStudio">
            <img src="../assets/home/circle.svg" alt="" />
            <img class="go-feature" src="../assets/home/go-r.svg" alt=""
          /></a>
        </div>
      </div>
      <div class="feature-detail">
        <img class="feature-avt" src="../assets/home/white bg.svg" alt="" />
        <div class="btn-feature">
          <a href="#SocLive">
            <img src="../assets/home/circle.svg" alt="" />
            <img class="go-feature" src="../assets/home/go-r.svg" alt=""
          /></a>
        </div>
      </div>
      <div class="feature-detail">
        <img class="feature-avt" src="../assets/home/image 112x.png" alt="" />
        <div class="btn-feature">
          <a href="#GoPage">
            <img src="../assets/home/circle.svg" alt="" />
            <img class="go-feature" src="../assets/home/go-r.svg" alt=""
          /></a>
        </div>
      </div>
    </div>
    <div id="about" v-for="(item, index) in abouts" :key="index">
      <div class="intro">
        <img src="../assets/home/building-1.png" alt="" />
        <h5>{{ item.firsTitle }}</h5>
        <h2>{{ item.titleProduct }}</h2>
        <h3>{{ item.nameProduct }}</h3>
        <h6>{{ item.firsContent }}</h6>
      </div>
      <div class="livestream">
        <div class="livestream-img">
          <img :src="`${assetsPath}${item.imageAbout.url}`" alt="" />
        </div>
        <div class="livestream-intro">
          <h5>{{ item.titleAbout }}</h5>
          <h6>{{ item.descriptionAbout }}</h6>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  data() {
    return {
      abouts: [],
    };
  },
  created() {},
  async mounted() {
    if (this.$route.params.locale == null) {
      await request
        .get(`abouts`)
        .then((response) => {
          this.abouts = response.data;

        })
        .catch((e) => {
          this.errors.push(e);
        });
    } else {
      await request
        .get(`abouts?_locale=${this.locale}`)
        .then((response) => {
          this.abouts = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    }
  },
  watch:{

  },
  computed: {
    locale() {
      return this.$route.params.locale;
    },
    assetsPath(){
      return process.env.VUE_APP_MY_ENV_ROOT_ASSETS 
      //${assetsPath}
    }
  },
};
</script>

<style>
body,
html {
  scroll-behavior: smooth;
}
.btn-feature {
  width: 35px;
  height: 35px;
  position: relative;
  top: 55px;
  right: 112px;
  align-items: center;
  text-align: center;
  justify-content: center;
  display: flex;
}
@keyframes example {
  0% {
    transform: translateX(-60%);
  }
  100% {
    transform: translateX(30%);
  }
}

.go-feature {
  position: relative !important;
  /* margin: 0 auto; */
  /* justify-content: center; */
  /* right: -33px; */
  top: 10px;
  left: 16px !important;
}
.btn-feature:hover .go-feature {
  animation-name: example;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  margin-top: 10px;
  /* left: 0;
    top: 0px;
    margin: 0 auto; */
}
</style>