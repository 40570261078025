<template>
  <section id="Invest" class="invest">
    <div>
      <h6 class="font-h6">{{ localisedInvest("investment") }}</h6>
      <h5 class="font-h5">{{ localisedInvest("title") }}</h5>
    </div>
    <div class="slide-invest">
      <div
        id="carouselExampleIndicators3"
        class="carousel slide"
        data-ride="carousel"
      >
        <ol class="carousel-indicators container" id="minimap2">
          <li
            data-target="#carouselExampleIndicators3"
            data-slide-to="0"
            class="active"
          ></li>
          <li data-target="#carouselExampleIndicators3" data-slide-to="1"></li>
          <li data-target="#carouselExampleIndicators3" data-slide-to="2"></li>
        </ol>
        <div class="carousel-inner" style="margin-left: 17px">
          <div class="carousel-item active">
            <div class="slide-prize container row-reverse" id="show-slide">
              <div class="div-left">
                <div class="right-child">
                  <div class="invest-div-title">
                    <img
                      style="width: 60px; height: 60px; object-fit: cover"
                      src="../../assets/home/logogst.png"
                      alt=""
                    />
                    <img
                      class="zone-startup"
                      src="../../assets/home/ZSU-Calgary-program-logo 1.png"
                      alt=""
                    />
                  </div>
                  <h5 class="font-18">{{ localisedInvest("titleSecond") }}</h5>
                  <div class="invest-div-decre">
                    <h6 class="font-17">{{ localisedInvest("content") }}</h6>
                  </div>
                </div>
              </div>
              <div class="div-right-img left-0">
                <img
                  class="img-prize"
                  src="../../assets/home/invest-img.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div
            class="carousel-item"
            v-for="(item, index) in invest"
            :key="index"
          >
            <div class="slide-prize container row-reverse" id="show-slide">
              <div class="div-left">
                <div class="right-child">
                  <div class="invest-div-title">
                    <img
                      style="width: 60px; height: 60px; object-fit: cover"
                      src="../../assets/home/logogst.png"
                      alt=""
                    />
                    <img
                      class="zone-startup"
                      :src="`${assetsPath}${item.investorLogo.url}`"
                      alt=""
                    />
                  </div>
                  <h5 class="font-18">{{ item.Title }}</h5>
                  <div class="invest-div-decre">
                    <h6 class="font-17">{{ item.Description }}</h6>
                  </div>
                </div>
              </div>
              <div class="div-right-img left-0">
                <img
                  class="img-invest"
                  :src="`${assetsPath}${item.Image.url}`"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          id="btn-click-prev2"
          data-target="#carouselExampleIndicators3"
          data-slide="prev"
        >
          <span class="carousel-control-prev-icon3" aria-hidden="true">
            <img src="../../assets/home/Vector 11.svg" alt="" />
          </span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          id="btn-click-next2"
          data-target="#carouselExampleIndicators3"
          data-slide="next"
        >
          <span class="carousel-control-next-icon3" aria-hidden="true">
            <img src="../../assets/home/Vector 12.svg" alt="" />
          </span>
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import investLang from "../../locales/lang";
import request from "@/utils/request";
import "../../assets/style.css";
export default {
  data() {
    return {
      invest: [],
      activeLocale: "vi",
    };
  },
  methods: {
    localisedInvest(key) {
      var langInvest = JSON.parse(JSON.stringify(investLang.investLang));
      return langInvest[this.activeLocale][key];
    },
  },
  async created() {
    if (this.$route.params.locale == null) {
      this.activeLocale = "vi";
    } else {
      this.activeLocale = this.$route.params.locale;
    }
    if (this.$route.params.locale == null) {
      await request
        .get(`invests`)
        .then((response) => {
          this.invest = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    } else {
      await request
        .get(`invests?_locale=${this.$route.params.locale}`)
        .then((response) => {
          this.invest = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    }
  },
  async mounted() {},
  computed: {
    assetsPath() {
      return process.env.VUE_APP_MY_ENV_ROOT_ASSETS;
      //${assetsPath}
    },
  },
};
</script>

<style>
.invest {
  overflow-x: hidden;
}
.img-invest {
  object-fit: cover;
}
</style>
